<template>
  <div
    class="main-container"
    :style="{
      '--editor-min-height': minHeight,
      '--editor-max-height': maxHeight,
    }"
  >
    <div
      class="editor-container editor-container_classic-editor editor-container_include-block-toolbar"
      id="editor-container"
    >
      <div class="editor-container__editor">
        <div :id="editorId" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

let MyUploadAdapter = null

export default {
  name: 'BaseEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    imageProviderUrl: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viewportOffset: {
      type: Object,
      default: () => ({ top: 84 }),
    },
    minHeight: {
      type: String,
      default: '200px',
    },
    maxHeight: {
      type: String,
      default: '250px',
    },
  },
  emits: ['input', 'blur'],
  data() {
    return {
      editorData: null,
      timeout: null,
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
    }),
    editorId() {
      return `editor-${this._uid}`
    },
  },
  watch: {
    disabled: {
      handler(value) {
        if (this.editorData) {
          if (value === true) {
            this.editorData.enableReadOnlyMode(this.editorId)
          } else {
            this.editorData.disableReadOnlyMode(this.editorId)
          }
        }
      },
    },
  },
  mounted() {
    const {
      ClassicEditor,
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BalloonToolbar,
      BlockQuote,
      BlockToolbar,
      Bold,
      CloudServices,
      Code,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FullPage,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      HorizontalLine,
      HtmlComment,
      HtmlEmbed,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Markdown,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      RemoveFormat,
      SelectAll,
      ShowBlocks,
      SimpleUploadAdapter,
      SourceEditing,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo,
    } = window.CKEDITOR

    const editorConfig = {
      ui: {
        viewportOffset: this.viewportOffset,
      },
      toolbar: {
        items: [
          'undo',
          'redo',
          '|',
          'sourceEditing',
          'showBlocks',
          '|',
          'heading',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          '|',
          'link',
          'insertImage',
          'insertTable',
          'highlight',
          'blockQuote',
          '|',
          'alignment',
          '|',
          'bulletedList',
          'numberedList',
          'todoList',
          'outdent',
          'indent',
        ],
        shouldNotGroupWhenFull: true,
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Autoformat,
        AutoImage,
        AutoLink,
        Autosave,
        BalloonToolbar,
        BlockQuote,
        BlockToolbar,
        Bold,
        CloudServices,
        Code,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        FullPage,
        GeneralHtmlSupport,
        Heading,
        Highlight,
        HorizontalLine,
        HtmlComment,
        HtmlEmbed,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        Markdown,
        MediaEmbed,
        Paragraph,
        PasteFromOffice,
        RemoveFormat,
        SelectAll,
        ShowBlocks,
        SimpleUploadAdapter,
        SourceEditing,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextTransformation,
        TodoList,
        Underline,
        Undo,
      ],
      balloonToolbar: [
        'bold',
        'italic',
        '|',
        'link',
        'insertImage',
        '|',
        'bulletedList',
        'numberedList',
      ],
      blockToolbar: [
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        'insertImage',
        'insertTable',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
      ],
      mediaEmbed: {
        previewsInData: true,
      },
      extraPlugins: [this.uploader],
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues: true,
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
          // {
          //   model: 'heading3',
          //   view: 'h3',
          //   title: 'Heading 3',
          //   class: 'ck-heading_heading3',
          // },
          // {
          //   model: 'heading4',
          //   view: 'h4',
          //   title: 'Heading 4',
          //   class: 'ck-heading_heading4',
          // },
          // {
          //   model: 'heading5',
          //   view: 'h5',
          //   title: 'Heading 5',
          //   class: 'ck-heading_heading5',
          // },
          // {
          //   model: 'heading6',
          //   view: 'h6',
          //   title: 'Heading 6',
          //   class: 'ck-heading_heading6',
          // },
        ],
      },
      htmlSupport: {
        allow: [
          {
            name: /^.*$/,
            styles: true,
            attributes: true,
            classes: true,
          },
        ],
      },
      image: {
        toolbar: [
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'resizeImage',
        ],
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file',
            },
          },
        },
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      menuBar: {
        isVisible: true,
      },
      placeholder: 'Type or paste your content here!',
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableProperties',
          'tableCellProperties',
        ],
      },
    }

    ClassicEditor.create(document.getElementById(this.editorId), editorConfig).then((editor) => {
      editor.setData(this.value || '')
      this.editorData = editor

      editor.model.document.on('change:data', () => {
        this.timeout && clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$emit('blur')
          this.$emit('input', editor.getData())
        }, 10)
      })

      if (this.disabled === true) {
        this.editorData.enableReadOnlyMode(this.editorId)
      } else {
        this.editorData.disableReadOnlyMode(this.editorId)
      }
    }).catch((error) => {
      console.error(error)
    })
  },
  created() {
    const that = this
    class MyUploadAdapterInit {
      constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader
        this.controller = new AbortController()
      }

      // Starts the upload process.
      upload() {
        return this.loader.file
          .then(file => new Promise((resolve, reject) => {
            const data = new FormData()
            data.append('file', file)
            that.axios.post(that.imageProviderUrl, data, {
              signal: this.controller.signal,
              onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                  this.loader.uploadTotal = progressEvent.total
                  this.loader.uploaded = progressEvent.loaded
                }
              },
            })
              .then((res) => {
                resolve({
                  default: res.data?.data?.url,
                })
              }).catch(() => {
                reject('Upload image failed!')
              })
          }))
      }

      // Aborts the upload process.
      abort() {
        this.controller.abort()
      }
    }
    MyUploadAdapter = MyUploadAdapterInit
  },
  methods: {
    uploader(editor) {
      if (!editor) return
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader)
      }
    },
  },
}
</script>

<!-- NOTE: Please Override CKEditor Styling on global css-->
<style scoped>
.main-container {
  font-family: 'Roboto';
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.ck-content {
  font-family: 'Roboto';
  line-height: 1.6;
  word-break: break-word;
  width: 100%;
}

::v-deep .ck-editor__main .ck-editor__editable {
  /* editing area */
  min-height: var(--editor-min-height);
  height: 100%;
  max-height: var(--editor-max-height);
  overflow: auto;
}

</style>
<!-- NOTE: Please Override CKEditor Styling on global css-->