var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "quizioner-detail"
    }
  }, _vm._l(_vm.data, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "mb": "20px",
        "data-id": item.id
      }
    }, [_c('c-text', {
      attrs: {
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), _vm.isArray(item.value) ? _c('ul', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "ml": "20px",
        "font-weight": "500"
      }
    }, _vm._l(item.value, function (it, index_) {
      return _c('li', {
        key: index_
      }, [_c('c-text', [_vm._v(" " + _vm._s(_vm._f("formatNullOrEmpty")(_vm._f("formatBoolean")(it))) + " ")])], 1);
    }), 0) : _vm.isImageUrl(item.value) ? _c('c-box', {
      attrs: {
        "bg": "gray.200",
        "w": "fit-content",
        "rounded": "lg",
        "font-weight": "500"
      }
    }, [_c('img', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "h": "404px",
        "w": "341px",
        "object-fit": "contain",
        "src": item.value,
        "alt": item.label
      }
    })]) : _c('c-text', {
      attrs: {
        "white-space": "pre-line",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatNullOrEmpty")(_vm._f("formatArray")(_vm._f("formatBoolean")(item.value)))) + " ")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }