var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_vm.image ? _c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": _vm.image,
            "alt": "image question"
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_vm.title ? _c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.description ? _c('BaseText', {
          attrs: {
            "size-mobile": "14px",
            "size-desktop": "16px",
            "color": "neutral.darkGray",
            "margin-top": "8px"
          }
        }, [_vm._v(" " + _vm._s(_vm.description) + " ")]) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_vm._t("footer")];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }