var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-container",
    style: {
      '--editor-min-height': _vm.minHeight,
      '--editor-max-height': _vm.maxHeight
    }
  }, [_c('div', {
    staticClass: "editor-container editor-container_classic-editor editor-container_include-block-toolbar",
    attrs: {
      "id": "editor-container"
    }
  }, [_c('div', {
    staticClass: "editor-container__editor"
  }, [_c('div', {
    attrs: {
      "id": _vm.editorId
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }