let page1 = {
  firstName: 'Nama Depan',
  middleName: 'Nama Tengah',
  lastName: 'Nama Belakang',
  gender: 'Jenis Kelamin',
  age: 'Usia',
  birthDate: 'Tanggal Lahir',
  address: 'Alamat Rumah',
  country: 'Negara',
  province: 'Provinsi',
  city: 'Kota',
  zipCode: 'Kode Pos',
  phone: 'Nomor HP (available whatsapp)',
  mainTarget: 'Apa tujuan utama kamu?',
  expectedDiet: 'Tipikal diet yang kamu harapkan',
  hasScales: 'Apakah kamu memiliki timbangan berat badan di rumah?',
  currentCondition: 'Mana yang paling mendeskripsikan kondisi Klien saat ini?',
}
let page2 = {
  weight: 'Berat Badan (kg)',
  height: 'Tinggi Badan (cm)',
  waistSize: 'Lingkar Pinggang (cm)',
  fat: 'Persen Lemak (Body Fat Percentage) (%)',
  muscleMass: 'Massa Otot (Muscle Mass) (kg)',
  visceralFat: 'Lemak Visceral (Visceral Fat)',
  // bodyWater: 'Persen Cairan Tubuh (Body Water Percentage)',
  // boneMass: 'Massa Tulang (Bone Mass)',
  // basalMetabolicRate: 'Kebutuhan Energi Basal atau BMR (Basal Metabolic Rate)',
}
let page3 = {
  foodPreference: 'Preferensi Makanan',
  mainFoodConsumption:
    'Biasanya, berapa kali kamu makan berat atau makan utama dalam 1 hari?',
  snackConsumption: 'Biasanya, berapa kali kamu makan cemilan dalam 1 hari?',
  foodSchedule: 'Jelaskan jadwal atau jam makanmu dalam 1 hari',
  foodConsumptionType:
    'Masakan atau menu apa yang hampir setiap hari kamu konsumsi?',
  dislikeFood: 'Makanan apa yang tidak kamu sukai?',
  favoriteFood: 'Kamu sukanya cita rasa makanan atau jenis makanan yang seperti apa?',
  expectedBreakfast:
    'Masakan atau menu apa yang biasa kamu konsumsi saat sarapan?',
  expectedLunchDinner:
    'Masakan atau menu apa yang biasa kamu konsumsi saat makan siang dan makan malam?',
  foodAllergies:
    'Adakah makanan yang menyebabkan kamu alergi, jika ada sebutkan jenis makannya?',
  fluidConsumption:
    'Berapa gelas rata-rata jumlah konsumsi air mineral (air putih) dalam satu hari?',
  vegetablesConsumption: 'Berapa banyak kamu mengonsumsi sayuran dalam sehari?',
  fruitConsumption: 'Berapa banyak kamu mengonsumsi buah dalam sehari?',
  beveragesComsumption:
    'Berapa banyak kamu mengonsumsi minuman kemasan atau minuman manis dalam sehari?',
  friedSnackConsumption:
    'Berapa banyak kamu mengonsumsi cemilan gorengan (diluar lauk dalam makan utama) dalam sehari?',
  saltySnackConsumption:
    'Berapa sering kamu mengonsumsi makanan ringan asin atau gurih dalam sehari (seperti makanan ringan kemasan, keripik, kacang goreng asin)?',
  sweetSnackConsumption:
    'Berapa sering kamu mengonsumsi cemilan manis dalam sehari (seperti kue-kue manis, brownies, cake, biskuit, coklat, wafer)?',
  personServeFood: 'Siapakah yang menyediakan makanan bagi kamu?',
  milkFoodAndDrinkConsumption:
    'Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman yang mengandung susu?',
  lifestyle:
    'Tak kenal maka tak sayang.. Tolong ceritakan dong hal lain tentang pola makan dan gaya hidup kamu yang perlu kami tahu :)',
  
  // breakfastHabits: 'Bagaimana kebiasaan sarapan kamu?',
  // breakfastFoodType: 'Jenis makanan yang kamu konsumsi saat sarapan?',
}
let page4 = {
  // activeLevel: 'Bagaimana tingkat keaktifan kamu dalam sehari?',
  sportType: 'Selama 1 minggu terakhir, pilih semua jenis aktivitas atau olahraga yang sudah kamu lakukan dan durasinya',
  weekdaysTypicalActivity: 'Tipikal keaktifan kamu pada hari kerja (weekdays)',
  weekendTypicalActivity:
    'Tipikal keaktifan kamu pada hari libur dan akhir pekan',
  reducePhysicalActivity: 
    'Apakah kamu pernah didiagnosa dokter untuk mengurangi aktifitas fisik kecuali yang direkomendasikan dokter?',
  physicalLimitationActivity: 'Apakah kamu memiliki keterbatasan berikut untuk melakukan aktivitas fisik?',
  averageSleepHours: 'Berapa jam rata-rata durasi tidur per hari',
  bedTime: 'Jam berapa biasa kamu tidur?',
}
let page6 = {
  hasDoctorDiagnose:
    'Apakah kamu pernah didiagnosa dokter untuk mengurangi aktifitas fisik kecuali yang direkomendasikan dokter?',
  hasChestPain:
    'Apakah kamu pernah merasa nyeri dada saat melakukan aktifitas fisik?',
  hasLostBalance:
    'Apakah kamu pernah merasa kehilangan keseimbangan karena pusing sampai kehilangan kesadaran/pingsan?',
  hasBoneProblem:
    'Apakah kamu mempunyai masalah tulang atau persendian yang menjadi lebih parah ketika melakukan aktivitas fisik',
  isOnTreatment:
    'Apakah saat ini kamu sedang dalam pengobatan/minum obat untuk tekanan darah tinggi atau penyakit jantung?',
  hasOtherCondition:
    'Selain poin diatas, apakah kamu mempunyai kondisi tertentu yang membatasi untuk beraktifitas fisik?',
}
let page7 = {
  mentalDisorder: 'Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?',
  disease:
    'Adakah saat ini kamu dinyatakan mengidap salah satu kondisi di bawah ini oleh dokter?',
  symptom: 'Adakah kamu mengalami keluhan dibawah ini dalam 3 hari terakhir?',
  drugsConsumption:
    'Adakah konsumsi obat-obatan rutin yang perlu kami ketahui? Jika ada, mohon sebutkan obat untuk apa atau merk obatnya',
  specialMedicine: 
    'Adakah konsumsi jenis obat, ramuan herbal atau minuman khusus yang sedang digunakan untuk diet? Jika ada, mohon sebutkan jenisnya dan tujuan penggunaannya',
  currentBehavior: 'Adakah kamu termasuk salah satu dibawah ini?',
  supplementConsumption:
    'Adakah jenis multivitamin atau tablet suplementasi yang kamu konsumsi? Jika ada, mohon sebutkan jenisnya atau merknya',
  communicationIntensity:
    'Seperti apa intensitas komunikasi yang kamu inginkan dalam program?',
  otherDisease:
    'Adakah riwayat penyakit atau kondisi kesehatan lainnya yang perlu kami ketahui?',
  job: 'Pekerjaan',
  lastEducation: 'Pendidikan Terakhir',
  expectProgram: 'Apa yang kamu harapkan dari program yang kamu pilih?',
  expectNutritionist: 'Apa lagi yang kamu harapkan dari Ahli Gizi Dietela?',
}

export default {
  ...page1,
  ...page2,
  ...page3,
  ...page4,
  ...page6,
  ...page7,
}
