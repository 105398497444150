var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('FormAccordionList', [_c('FormAccordionListItem', {
    attrs: {
      "label": "Pagi",
      "is-error": false
    }
  }, [_vm.breakfast ? _c('FormFood', {
    attrs: {
      "label": "Makan Pagi",
      "time": "Pukul 00:00 - 09:00",
      "placeholder-type": "food",
      "is-eat": _vm.breakfast.isEat ? true : false,
      "has-photo": _vm.breakfast.hasPhoto ? true : false,
      "description-food": _vm.breakfast.descriptionFood,
      "photos": _vm.breakfast.photos,
      "is-description-food-valid": true,
      "type": "view"
    }
  }) : _vm._e(), _vm.morningSnack ? _c('FormFood', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Snack/cemilan Pagi",
      "time": "Pukul 10:00 - 11:00",
      "placeholder-type": "snack",
      "is-eat": _vm.morningSnack.isEat ? true : false,
      "has-photo": _vm.morningSnack.hasPhoto ? true : false,
      "description-food": _vm.morningSnack.descriptionFood,
      "photos": _vm.morningSnack.photos,
      "is-description-food-valid": true,
      "type": "view"
    }
  }) : _vm._e()], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Siang",
      "is-error": false
    }
  }, [_vm.lunch ? _c('FormFood', {
    attrs: {
      "label": "Makan Siang",
      "time": "Pukul 12:00 - 14:00",
      "placeholder-type": "food",
      "is-eat": _vm.lunch.isEat ? true : false,
      "has-photo": _vm.lunch.hasPhoto ? true : false,
      "description-food": _vm.lunch.descriptionFood,
      "photos": _vm.lunch.photos,
      "is-description-food-valid": true,
      "type": "view"
    }
  }) : _vm._e(), _vm.afternoonSnack ? _c('FormFood', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Snack/cemilan Siang",
      "time": "Pukul 15:00 - 17:00",
      "placeholder-type": "snack",
      "is-eat": _vm.afternoonSnack.isEat ? true : false,
      "has-photo": _vm.afternoonSnack.hasPhoto ? true : false,
      "description-food": _vm.afternoonSnack.descriptionFood,
      "photos": _vm.afternoonSnack.photos,
      "is-description-food-valid": true,
      "type": "view"
    }
  }) : _vm._e()], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Malam",
      "is-error": false
    }
  }, [_vm.dinner ? _c('FormFood', {
    attrs: {
      "label": "Makan Malam",
      "time": "Pukul 18:00 - 20:00",
      "placeholder-type": "food",
      "is-eat": _vm.dinner.isEat ? true : false,
      "has-photo": _vm.dinner.hasPhoto ? true : false,
      "description-food": _vm.dinner.descriptionFood,
      "photos": _vm.dinner.photos,
      "is-description-food-valid": true,
      "type": "view"
    }
  }) : _vm._e(), _vm.nightSnack ? _c('FormFood', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Snack/cemilan Malam",
      "time": "Pukul 21:00 - 00:00",
      "placeholder-type": "snack",
      "is-eat": _vm.nightSnack.isEat ? true : false,
      "has-photo": _vm.nightSnack.hasPhoto ? true : false,
      "description-food": _vm.nightSnack.descriptionFood,
      "photos": _vm.nightSnack.photos,
      "is-description-food-valid": true,
      "type": "view"
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }