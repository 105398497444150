<template>
  <c-box>
    <c-box
      position="fixed"
      right="0"
      :top="['100px', '25vh']"
      background-color="#F4CC46"
      padding-inline="20px"
      padding-block="4px"
      border-bottom-left-radius="12px"
      border-top-left-radius="12px"
      cursor="pointer"
      transition-duration="300ms"
      transition-property="ease-in-out"
      z-index="10"
      style="writing-mode: tb;"
      :_hover="{
        paddingRight: '6px',
  
      }"
      @click="toggleDrawer"
    >
      <c-flex
        align-items="center"
        font-weight="500"
        gap="8px"
        transform="rotate(180deg)"
      >
        Catatan Khusus
        <inline-svg
          :src="require('@/assets/icons/icon-arrow-right.svg')"
          height="18"
          width="18"
          fill="#000"
        />
      </c-flex>
    </c-box>
    <transition name="slide-right">
      <c-box
        v-if="isOpen"
        position="fixed"
        right="0px"
        :top="['100px', '25vh']"
        width="100%"
        max-width="400px"
        display="flex"
        flex-direction="column"
        align-items="flex-end"
        z-index="10"
      >
        <c-box
          background-color="#F4CC46"
          width="fit-content"
          padding-block="4px"
          padding-inline="20px"
          border-top-left-radius="8px"
          cursor="pointer"
          @click="toggleDrawer"
        >
          <c-flex
            align-items="center"
            font-weight="500"
            gap="8px"
          >
            Catatan Khusus
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-right.svg')"
              height="18"
              width="18"
              fill="#000"
            />
          </c-flex>
        </c-box>
        <c-box
          width="100%"
          border-top-left-radius="8px"
          border-bottom-left-radius="8px"
          padding-block="12px"
          background-color="#FFF"
          :box-shadow="['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
        >
          <c-flex
            v-if="isLoading"
            :max-height="['55vh']"
            padding-inline="8px"
            gap="10px"
            flex-direction="column"
            overflow="hidden"
          >
            <c-box
              v-for="i in 5"
              :key="i"
              :w="['100%']"
              :h="['48px', '62px']"
              border-radius="8px"
              overflow="hidden"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                animation="fade"
              />
            </c-box>
          </c-flex>
          <c-flex
            v-else
            :max-height="['55vh', '55vh']"
            padding-inline="8px"
            gap="10px"
            flex-direction="column"
            overflow-y="auto"
          >
            <c-flex
              v-for="note in notes"
              :key="note?.id"
              width="100%"
              flex-direction="column"
              border-color="primary.400"
              border-width="1px"
              border-radius="12px"
            >
              <c-flex
                align-items="center"
                justify-content="space-between"
              >
                <c-box
                  background-color="primary.50"
                  :padding="['4px 12px']"
                  border-color="primary.400"
                  border-right-width="1px"
                  border-bottom-width="1px"
                  border-bottom-right-radius="12px"
                  border-top-left-radius="12px"
                  :font-size="['12px', '14px']"
                >
                  {{ note?.question }}
                </c-box>
              </c-flex>
              <c-box
                padding="4px 12px"
                :font-size="['12px', '14px']"
              >
                <ul
                  v-if="isArray(note?.answer)"
                  v-chakra
                  ml="20px"
                  font-weight="500"
                >
                  <li
                    v-for="(it, index_) in note?.answer"
                    :key="index_"
                  >
                    <c-text>
                      {{ it | formatBoolean | formatNullOrEmpty }}
                    </c-text>
                  </li>
                </ul>
                <c-box
                  v-else
                  white-space="pre-line"
                  font-weight="500"
                >
                  {{ note?.answer | formatBoolean | formatArray | formatNullOrEmpty }}
                </c-box>
              </c-box>
            </c-flex>
          </c-flex>
        </c-box>
      </c-box>
    </transition>
  </c-box>
</template>

<script>
import { reqNutritionist_mealPlans_specificNote } from '@/requests/dietela-api/nutritionist/other'
import { reqAdmin_mealPlans_specificNote } from '@/requests/dietela-api/admin/meal-plan'
import quizionaryValueMap from '@/utils/quizionary-value-map'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'DrawerSpecificNote',
  components: {
    VueSkeletonLoader,
  },
  filters: {
    formatNullOrEmpty(val) {
      if (val == null || val === '') {
        return '-'
      }
      return val
    },
    formatBoolean(val) {
      if (val === true) return 'ya'
      if (val === false) return 'tidak'
      return val
    },
    formatArray(val) {
      if (Array.isArray(val)) return val.join(', ')
      return val
    },
  },
  data() {
    return {
      isOpen: false,
      notes: [],
      isLoading: false,
    }
  },
  methods: {
    isArray(item) {
      return Array.isArray(item)
    },
    toggleDrawer() {
      this.isOpen = !this.isOpen
      if (this.isOpen && this.notes.length === 0) {
        this.getSpecificNotes()
      }
    },
    async getSpecificNotes() {
      this.isLoading = true
      if (this.authRole === 'nutritionist') {
        const res = await reqNutritionist_mealPlans_specificNote(this.$store.getters.axios, { clientId: this.$route.params.clientId })
        this.notes = res?.data?.data?.map((item) => ({
          ...item,
          answer: quizionaryValueMap[item?.alias]?.(item?.answer) || item?.answer,
        }))
      } else {
        const res = await reqAdmin_mealPlans_specificNote(this.$store.getters.axios, { clientId: this.$route.params.clientId })
        this.notes = res?.data?.data?.map((item) => ({
          ...item,
          answer: quizionaryValueMap[item?.alias]?.(item?.answer) || item?.answer,
        }))
      }
      this.isLoading = false
    },
  },
}
</script>