<template>
  <c-box>
    <FormAccordionList>
      <FormAccordionListItem
        label="Pagi"
        :is-error="false"
      >
        <FormFood
          v-if="breakfast"
          label="Makan Pagi"
          time="Pukul 00:00 - 09:00"
          placeholder-type="food"
          :is-eat="breakfast.isEat ? true : false"
          :has-photo="breakfast.hasPhoto ? true : false"
          :description-food="breakfast.descriptionFood"
          :photos="breakfast.photos"
          :is-description-food-valid="true"
          type="view"
        />

        <FormFood
          v-if="morningSnack"
          label="Snack/cemilan Pagi"
          time="Pukul 10:00 - 11:00"
          placeholder-type="snack"
          :is-eat="morningSnack.isEat ? true : false"
          :has-photo="morningSnack.hasPhoto ? true : false"
          :description-food="morningSnack.descriptionFood"
          :photos="morningSnack.photos"
          style="margin-top: 16px"
          :is-description-food-valid="true"
          type="view"
        />
      </FormAccordionListItem>
      <FormAccordionListItem
        label="Siang"
        :is-error="false"
      >
        <FormFood
          v-if="lunch"
          label="Makan Siang"
          time="Pukul 12:00 - 14:00"
          placeholder-type="food"
          :is-eat="lunch.isEat ? true : false"
          :has-photo="lunch.hasPhoto ? true : false"
          :description-food="lunch.descriptionFood"
          :photos="lunch.photos"
          :is-description-food-valid="true"
          type="view"
        />

        <FormFood
          v-if="afternoonSnack"
          label="Snack/cemilan Siang"
          time="Pukul 15:00 - 17:00"
          placeholder-type="snack"
          :is-eat="afternoonSnack.isEat ? true : false"
          :has-photo="afternoonSnack.hasPhoto ? true : false"
          :description-food="afternoonSnack.descriptionFood"
          :photos="afternoonSnack.photos"
          style="margin-top: 16px"
          :is-description-food-valid="true"
          type="view"
        />
      </FormAccordionListItem>
      <FormAccordionListItem
        label="Malam"
        :is-error="false"
      >
        <FormFood
          v-if="dinner"
          label="Makan Malam"
          time="Pukul 18:00 - 20:00"
          placeholder-type="food"
          :is-eat="dinner.isEat ? true : false"
          :has-photo="dinner.hasPhoto ? true : false"
          :description-food="dinner.descriptionFood"
          :photos="dinner.photos"
          :is-description-food-valid="true"
          type="view"
        />

        <FormFood
          v-if="nightSnack"
          label="Snack/cemilan Malam"
          time="Pukul 21:00 - 00:00"
          placeholder-type="snack"
          :is-eat="nightSnack.isEat ? true : false"
          :has-photo="nightSnack.hasPhoto ? true : false"
          :description-food="nightSnack.descriptionFood"
          :photos="nightSnack.photos"
          style="margin-top: 16px"
          :is-description-food-valid="true"
          type="view"
        />
      </FormAccordionListItem>
    </FormAccordionList>
  </c-box>
</template>

<script>
import getMenuCategory from '@/utils/get-menu-category'
import FormAccordionList from '@/views/client/kuisioner/forms/form-accordion/list.vue'
import FormAccordionListItem from '@/views/client/kuisioner/forms/form-accordion/list-item.vue'
import FormFood from '@/views/client/kuisioner/forms/form-food.vue'

export default {
  name: 'DetailKuis5',
  components: {
    FormAccordionList,
    FormAccordionListItem,
    FormFood,
  },
  filters: {
    getMenuCategory(val) {
      return getMenuCategory(val)
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    breakfast() {
      if (this.data.length > 0) {
        return this.data.find((el) => el?.value?.category == 'breakfast')?.value
      }
      return null
    },
    morningSnack() {
      if (this.data.length > 0) {
        return this.data.find((el) => el?.value?.category == 'morningSnack')?.value
      }
      return null
    },
    lunch() {
      if (this.data.length > 0) {
        return this.data.find((el) => el?.value?.category == 'lunch')?.value
      }
      return null
    },
    afternoonSnack() {
      if (this.data.length > 0) {
        return this.data.find((el) => el?.value?.category == 'afternoonSnack')?.value
      }
      return null
    },
    dinner() {
      if (this.data.length > 0) {
        return this.data.find((el) => el?.value?.category == 'dinner')?.value
      }
      return null
    },
    nightSnack() {
      if (this.data.length > 0) {
        return this.data.find((el) => el?.value?.category == 'nightSnack')?.value
      }
      return null
    },
  },
}
</script>
