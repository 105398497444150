<template>
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    :with-button-close="false"
  >
    <template #header>
      <c-box
        display="flex"
        justify-content="center"
        margin="24px 0 0 0"
      >
        <c-image
          v-if="image"
          height="120px"
          object-fit="cover"
          :src="image"
          alt="image question"
        />
      </c-box>
    </template>
    <template #body>
      <c-box
        justify-content="center"
        padding="30px 24px 20px 24px"
        text-align="center"
      >
        <BaseText
          v-if="title"
          size-mobile="16px"
          size-desktop="18px"
          color="primary.400"
        >
          {{ title }}
        </BaseText>

        <BaseText
          v-if="description"
          size-mobile="14px"
          size-desktop="16px"
          color="neutral.darkGray"
          margin-top="8px"
        >
          {{ description }}
        </BaseText>
      </c-box>
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/elements/base-modal.vue'
import { CBox, CImage } from '@chakra-ui/vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'ModalSimpleInfo',
  components: { CBox, BaseText, CImage, BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '', // require('@/assets/images/image-question.svg'),
    },
    title: {
      type: String,
      default: '', // 'Data belum tersimpan, yakin mau keluar halaman?',
    },
    description: {
      type: String,
      default: '', // 'Data belum tersimpan, yakin mau keluar halaman?',
    },
  },
}
</script>
