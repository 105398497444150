<template>
  <BaseModal
    :is-open="!!isOpen"
    :close-on-overlay-click="false"
    :with-button-close="false"
    :has-footer="false"
    size="1000px"
  >
    <template #header>
      <c-box
        position="relative"
        display="flex"
        justify-content="center"
        margin-block="16px"
      >
        <c-text
          :font-size="['20px', '28px']"
          font-weight="600"
        >
          {{ title }}
        </c-text>
        <c-box
          position="absolute"
          :right="['8px', '16px']"
          :width="['24px', '28px']"
          :height="['24px', '28px']"
          cursor="pointer"
          @click="$emit('close')"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-close.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
      </c-box>
    </template>
    <template #body>
      <c-box
        v-if="isLoading"
        text-align="center"
        margin-block="50px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Riwayat Catatan {{ type === 'nutritionist' ? 'Ahli Gizi' : 'Client Manager' }}...
        </c-text>
      </c-box>
      <c-box
        v-else
        display="flex"
        flex-direction="column"
        align-items="center"
        :px="['12px', '24px']"
        mb="16px"
        mx="auto"
        gap="16px"
        :max-height="['75vh', '70vh']"
        overflow-y="auto"
      >
        <c-flex
          v-for="(item, i) in notes"
          :key="i"
          width="100%"
          flex-direction="column"
          border-color="primary.400"
          border-width="1px"
          border-radius="16px"
        >
          <c-flex
            align-items="center"
            justify-content="space-between"
          >
            <c-box
              background-color="primary.50"
              :padding="['5px 8px', '10px 16px']"
              border-color="primary.400"
              border-right-width="1px"
              border-bottom-width="1px"
              border-bottom-right-radius="16px"
              border-top-left-radius="16px"
              :font-size="['12px', '14px']"
            >
              {{ item?.createdAt ? formatDateV2(
                item?.createdAt,
                7
              ) : '-' }}
            </c-box>
            <c-box
              v-if="i === 0"
              color="primary.400"
              :font-size="['12px', '14px']"
              :padding-right="['5px', '10px']"
            >
              Baru ditambahkan
            </c-box>
          </c-flex>
          <c-box
            padding="16px"
            :font-size="['14px', '16px']"
          >
            <p
              class="ck-content"
              v-html="item.note"
            />
          </c-box>
        </c-flex>
      </c-box>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/elements/base-modal.vue'
import { formatDateV2 } from '@/utils/format-date'
import { reqNutritionist_mealPlans_noteHistory } from '@/requests/dietela-api/nutritionist/other'
import { reqAdmin_mealPlans_noteHistory } from '@/requests/dietela-api/admin/meal-plan'
export default {
  name: 'ModalMealPlanNoteHistory',
  components: {
    BaseModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    mealPlanId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'nutritionist',
    },
  },
  emits: ['close'],
  data() {
    return {
      isLoading: false,
      mealPlansNoteHistory: [],
    }
  },
  computed: {
    title() {
      return `Riwayat Catatan ${this.type === 'nutritionist' ? 'Ahli Gizi' : 'Client Manager'}`
    },
    notes() {
      switch(this.type) {
        case 'nutritionist': 
          return this.sortByCreatedAt(this.mealPlansNoteHistory?.nutritionist || [])
        case 'clientManager': 
          return this.sortByCreatedAt(this.mealPlansNoteHistory?.clientManager || [])
        default:
          return []
      }
    },
  },
  watch: {
    isOpen: {
      async handler(val) {
        if (val) {
          this.isLoading = true
          this.mealPlansNoteHistory = []
          if (this.authRole === 'nutritionist') {
            const response = await reqNutritionist_mealPlans_noteHistory(this.$store.getters.axios, { id: this.mealPlanId })
            this.mealPlansNoteHistory = response?.data?.data
          } else {
            const response = await reqAdmin_mealPlans_noteHistory(this.$store.getters.axios, { id: this.mealPlanId })
            this.mealPlansNoteHistory = response?.data?.data  
          }
          this.isLoading = false
        }
      },
    },
  },
  methods: {
    formatDateV2,
    sortByCreatedAt(arr) {
      return arr.length > 0 ? arr?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)) : []
    },
  },
}
</script>

<style>

</style>