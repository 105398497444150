var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    staticStyle: {
      "writing-mode": "tb"
    },
    attrs: {
      "position": "fixed",
      "right": "0",
      "top": ['100px', '25vh'],
      "background-color": "#F4CC46",
      "padding-inline": "20px",
      "padding-block": "4px",
      "border-bottom-left-radius": "12px",
      "border-top-left-radius": "12px",
      "cursor": "pointer",
      "transition-duration": "300ms",
      "transition-property": "ease-in-out",
      "z-index": "10",
      "_hover": {
        paddingRight: '6px'
      }
    },
    on: {
      "click": _vm.toggleDrawer
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "font-weight": "500",
      "gap": "8px",
      "transform": "rotate(180deg)"
    }
  }, [_vm._v(" Catatan Khusus "), _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-right.svg'),
      "height": "18",
      "width": "18",
      "fill": "#000"
    }
  })], 1)], 1), _c('transition', {
    attrs: {
      "name": "slide-right"
    }
  }, [_vm.isOpen ? _c('c-box', {
    attrs: {
      "position": "fixed",
      "right": "0px",
      "top": ['100px', '25vh'],
      "width": "100%",
      "max-width": "400px",
      "display": "flex",
      "flex-direction": "column",
      "align-items": "flex-end",
      "z-index": "10"
    }
  }, [_c('c-box', {
    attrs: {
      "background-color": "#F4CC46",
      "width": "fit-content",
      "padding-block": "4px",
      "padding-inline": "20px",
      "border-top-left-radius": "8px",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.toggleDrawer
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "font-weight": "500",
      "gap": "8px"
    }
  }, [_vm._v(" Catatan Khusus "), _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-right.svg'),
      "height": "18",
      "width": "18",
      "fill": "#000"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "border-top-left-radius": "8px",
      "border-bottom-left-radius": "8px",
      "padding-block": "12px",
      "background-color": "#FFF",
      "box-shadow": ['none', '0px 4px 8px rgba(0, 0, 0, 0.2)']
    }
  }, [_vm.isLoading ? _c('c-flex', {
    attrs: {
      "max-height": ['55vh'],
      "padding-inline": "8px",
      "gap": "10px",
      "flex-direction": "column",
      "overflow": "hidden"
    }
  }, _vm._l(5, function (i) {
    return _c('c-box', {
      key: i,
      attrs: {
        "w": ['100%'],
        "h": ['48px', '62px'],
        "border-radius": "8px",
        "overflow": "hidden"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade"
      }
    })], 1);
  }), 1) : _c('c-flex', {
    attrs: {
      "max-height": ['55vh', '55vh'],
      "padding-inline": "8px",
      "gap": "10px",
      "flex-direction": "column",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.notes, function (note) {
    return _c('c-flex', {
      key: note === null || note === void 0 ? void 0 : note.id,
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "border-color": "primary.400",
        "border-width": "1px",
        "border-radius": "12px"
      }
    }, [_c('c-flex', {
      attrs: {
        "align-items": "center",
        "justify-content": "space-between"
      }
    }, [_c('c-box', {
      attrs: {
        "background-color": "primary.50",
        "padding": ['4px 12px'],
        "border-color": "primary.400",
        "border-right-width": "1px",
        "border-bottom-width": "1px",
        "border-bottom-right-radius": "12px",
        "border-top-left-radius": "12px",
        "font-size": ['12px', '14px']
      }
    }, [_vm._v(" " + _vm._s(note === null || note === void 0 ? void 0 : note.question) + " ")])], 1), _c('c-box', {
      attrs: {
        "padding": "4px 12px",
        "font-size": ['12px', '14px']
      }
    }, [_vm.isArray(note === null || note === void 0 ? void 0 : note.answer) ? _c('ul', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "ml": "20px",
        "font-weight": "500"
      }
    }, _vm._l(note === null || note === void 0 ? void 0 : note.answer, function (it, index_) {
      return _c('li', {
        key: index_
      }, [_c('c-text', [_vm._v(" " + _vm._s(_vm._f("formatNullOrEmpty")(_vm._f("formatBoolean")(it))) + " ")])], 1);
    }), 0) : _c('c-box', {
      attrs: {
        "white-space": "pre-line",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatNullOrEmpty")(_vm._f("formatArray")(_vm._f("formatBoolean")(note === null || note === void 0 ? void 0 : note.answer)))) + " ")])], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }