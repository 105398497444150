var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpen,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "has-footer": false,
      "size": "1000px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "position": "relative",
            "display": "flex",
            "justify-content": "center",
            "margin-block": "16px"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['20px', '28px'],
            "font-weight": "600"
          }
        }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-box', {
          attrs: {
            "position": "absolute",
            "right": ['8px', '16px'],
            "width": ['24px', '28px'],
            "height": ['24px', '28px'],
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-circle-close.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#D32737"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.isLoading ? _c('c-box', {
          attrs: {
            "text-align": "center",
            "margin-block": "50px"
          }
        }, [_c('c-spinner', {
          attrs: {
            "thickness": "4px",
            "speed": "0.65s",
            "empty-color": "green.200",
            "color": "primary.400",
            "size": "xl"
          }
        }), _c('c-text', {
          attrs: {
            "color": "primary.400",
            "font-weight": "500"
          }
        }, [_vm._v(" Memuat data Riwayat Catatan " + _vm._s(_vm.type === 'nutritionist' ? 'Ahli Gizi' : 'Client Manager') + "... ")])], 1) : _c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": ['12px', '24px'],
            "mb": "16px",
            "mx": "auto",
            "gap": "16px",
            "max-height": ['75vh', '70vh'],
            "overflow-y": "auto"
          }
        }, _vm._l(_vm.notes, function (item, i) {
          return _c('c-flex', {
            key: i,
            attrs: {
              "width": "100%",
              "flex-direction": "column",
              "border-color": "primary.400",
              "border-width": "1px",
              "border-radius": "16px"
            }
          }, [_c('c-flex', {
            attrs: {
              "align-items": "center",
              "justify-content": "space-between"
            }
          }, [_c('c-box', {
            attrs: {
              "background-color": "primary.50",
              "padding": ['5px 8px', '10px 16px'],
              "border-color": "primary.400",
              "border-right-width": "1px",
              "border-bottom-width": "1px",
              "border-bottom-right-radius": "16px",
              "border-top-left-radius": "16px",
              "font-size": ['12px', '14px']
            }
          }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.createdAt ? _vm.formatDateV2(item === null || item === void 0 ? void 0 : item.createdAt, 7) : '-') + " ")]), i === 0 ? _c('c-box', {
            attrs: {
              "color": "primary.400",
              "font-size": ['12px', '14px'],
              "padding-right": ['5px', '10px']
            }
          }, [_vm._v(" Baru ditambahkan ")]) : _vm._e()], 1), _c('c-box', {
            attrs: {
              "padding": "16px",
              "font-size": ['14px', '16px']
            }
          }, [_c('p', {
            staticClass: "ck-content",
            domProps: {
              "innerHTML": _vm._s(item.note)
            }
          })])], 1);
        }), 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }