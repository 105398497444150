<template>
  <div id="quizioner-detail">
    <c-box
      v-for="(item, index) in data"
      :key="index"
      mb="20px"
      :data-id="item.id"
    >
      <c-text color="primary.400">
        {{ item.label }}
      </c-text>
      <ul
        v-if="isArray(item.value)"
        v-chakra
        ml="20px"
        font-weight="500"
      >
        <li
          v-for="(it, index_) in item.value"
          :key="index_"
        >
          <c-text>
            {{ it | formatBoolean | formatNullOrEmpty }}
          </c-text>
        </li>
      </ul>
      <c-box
        v-else-if="isImageUrl(item.value)"
        bg="gray.200"
        w="fit-content"
        rounded="lg"
        font-weight="500"
      >
        <img
          v-chakra
          h="404px"
          w="341px"
          object-fit="contain"
          :src="item.value"
          :alt="item.label"
        >
      </c-box>
      <c-text
        v-else
        white-space="pre-line"
        font-weight="500"
      >
        {{ item.value | formatBoolean | formatArray | formatNullOrEmpty }}
      </c-text>
    </c-box>
  </div>
</template>

<script>
export default {
  name: 'QuizionaryDetail',
  filters: {
    formatNullOrEmpty(val) {
      if (val == null || val === '') {
        return '-'
      }
      return val
    },
    formatBoolean(val) {
      if (val === true) return 'ya'
      if (val === false) return 'tidak'
      return val
    },
    formatArray(val) {
      if (Array.isArray(val)) return val.join(', ')
      return val
    },
  },
  props: ['data'],
  methods: {
    isArray(item) {
      return Array.isArray(item)
    },
    isImageUrl(item) {
      let isMatch = /^https?:\/\/.*\.(jpeg|jpg|gif|png)$/gim.test(item)
      return isMatch
    },
  },
}
</script>
